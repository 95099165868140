import React from "react";
import { Navbar } from "../../components";
import { Recipes } from "../../container";
import { Route, Routes } from "react-router-dom";

function Header() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Recipes />} />
        <Route path="/vegetarian" element={<Recipes />} />
        <Route path="/main-dishes" element={<Recipes />} />
        <Route path="/cakes" element={<Recipes />} />
        <Route path="/fast-food" element={<Recipes />} />
        <Route path="/kids-menu" element={<Recipes />} />
        <Route path="/soups" element={<Recipes />} />
      </Routes>
    </>
  );
}

export default Header;
