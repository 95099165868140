import home from "../assets/icons/ic_home.svg";
import vegetarian from "../assets/icons/ic_vegetarian.svg";
import main from "../assets/icons/ic_main.svg";
import cake from "../assets/icons/ic_cake.svg";
import fastFood from "../assets/icons/ic_fast-food.svg";
import kids from "../assets/icons/ic_kids.svg";
import soup from "../assets/icons/ic_soup.svg";
import { MenuItem } from "../interfaces/app";

export const menuItems: MenuItem[] = [
  {
    id: 0,
    title: "Home",
    titleMobile: "Home",
    active: true,
    url: "/",
    query: "",
    icon: home,
    cName: "navbar-link-mobile",
  },
  {
    id: 1,
    title: "Vegetarian",
    titleMobile: "Vegetarian",
    active: false,
    url: "/vegetarian",
    query: "vegetarian",
    icon: vegetarian,
    cName: "navbar-link",
  },
  {
    id: 2,
    title: "Main Dishes",
    titleMobile: "Main Dishes",
    active: false,
    url: "/main-dishes",
    query: "main course",
    icon: main,
    cName: "navbar-link",
  },
  {
    id: 3,
    title: "Cakes",
    titleMobile: "Cakes",
    active: false,
    url: "/cakes",
    query: "dessert",
    icon: cake,
    cName: "navbar-link",
  },
  {
    id: 4,
    title: "Fast Food",
    titleMobile: "Fast Food",
    active: false,
    url: "/fast-food",
    query: "fingerfood",
    icon: fastFood,
    cName: "navbar-link",
  },
  {
    id: 5,
    title: "Kids Menu",
    titleMobile: "Kids Menu",
    active: false,
    url: "/kids-menu",
    query: "snack",
    icon: kids,
    cName: "navbar-link",
  },
  {
    id: 6,
    title: "Soups",
    titleMobile: "Soups",
    active: false,
    url: "/soups",
    query: "soup",
    icon: soup,
    cName: "navbar-link",
  },
];
