import RecipeApp from "./pages/RecipeApp";

function App() {
  return (
    <div className="app">
      <RecipeApp />
    </div>
  );
}

export default App;
