export const UIhelper = {
  shortenText(str: string): [string, string] {
    const words = str.split(" ");
    if (words.length >= 2) {
      return [words[0], words[words.length - 1]];
    } else {
      return [str, ""];
    }
  },
  recipeName(str: string): string {
    return str === "Home" ? "New Recipes" : str;
  },
  recipeReady(num: number): string {
    return num === 1 ? `${num} minute` : `${num} minutes`;
  },
  recipeDifficulty(num: number): string {
    if (num >= 10) return "difficult";
    if (num >= 7) return "medium";
    else return "easy";
  },
  recipeRating(): number {
    return (
      Math.floor(Math.random() * 2) + Math.floor(Math.random() * 10) / 10 + 3
    );
  },
};
