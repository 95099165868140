import React from "react";
import "./Banner.scss";

const Banner: React.FC = () => {
  return (
    <div className="banner">
      <div className="banner__text">
        <h1 className="banner-title">Recipes</h1>
        <h2 className="banner-subtitle">For Everyone</h2>
      </div>
    </div>
  );
};

export default Banner;
