import { Footer, Header } from "../container";

function RecipeApp() {
  return (
    <>
      <Header />
      <Footer />
    </>
  );
}

export default RecipeApp;
